(function(){
'use strict';

scAddressLookup.$inject = ["loadGoogleMapsAPI"];
angular.module('classy').directive('scAddressLookup', scAddressLookup);

function scAddressLookup(loadGoogleMapsAPI) {
  return {
    require: ['ngModel', '^?form'],
    scope: { class: '@', name: '@', placeholder: '@', parser: '&', required: '=?' },
    template: '<input type="text" class={{class}} name={{name}} placeholder={{placeholder}} required data-sc-form-error="highlight if {{name}} fails" />',
    link: function link(scope, element, attrs, ctrls) {
      var ngModelCtrl = ctrls[0],
          $input = element.find('input');

      scope.autocomplete = null;
      scope.placeholder = attrs.placeholder;

      /* -------------------------------------------------------------------- *
       * Prevent form submission on enter
       * -------------------------------------------------------------------- */

      $input.on('keydown', function (event) {
        if (event.keyCode == 13) {
          event.preventDefault();

          // Stop propagation to prevent form submission
          event.stopPropagation();
        }
      });

      $input.on('change', function (event) {
        var value = event.target.value;
        ngModelCtrl.$setViewValue(value);
      });

      $input.on('focus', function () {
        // This is a workaround to prevent the browser from auto-filling the address field
        // when the user selects an address from the Places Autocomplete widget.
        // Chrome won't accept the 'off' value, so we use a non-standard value instead.
        $input.attr('autocomplete', 'non-standard-value');
      });

      $input.on('blur', function () {
        // When the field loses focus, we remove the autocomplete attribute.
        // This allows the browser to auto-fill the address field when the user selects an address
        // through the other fields in the form.
        $input.removeAttr('autocomplete');
      });

      /* -------------------------------------------------------------------- *
       * Load and Init
       * -------------------------------------------------------------------- */

      loadGoogleMapsAPI.then(function () {
        // Link up autocomplete; limit to street addresses (not "Taj Mahal")
        scope.autocomplete = new google.maps.places.Autocomplete($input[0], {
          types: ['address']
        });

        // When a defined location is selected, update model
        scope.autocomplete.addListener('place_changed', function () {
          var place = scope.autocomplete.getPlace();
          if (!place.address_components) {
            return;
          }

          scope.successfulQuery = $input.val();

          var interpreted = interpret(place);

          $input.val(interpreted.address);
          scope.parser({ response: interpreted });
        });

        // Hack to fix iOS.
        // Based on http://vedanova.com/tech/2014/11/24/google-maps-places-autocomplete-bug-on-ios.html
        $input.on('focus', function () {
          var container = document.getElementsByClassName('pac-container');
          angular.element(container).attr('data-tap-disabled', 'true');
          angular.element(container).on('touchend', function ($e) {
            $e.originalEvent.stopPropagation();
          });
        });
      });

      /* -------------------------------------------------------------------- *
       * Utils
       * -------------------------------------------------------------------- */

      function interpret(place) {
        var interpreted = {
          subpremise: '',
          number: '',
          street: '',
          city: '',
          state: '',
          country: '',
          postalCode: '',
          postalCodeSuffix: ''
        };

        // Find the country first, as it helps determine how we should identify the city.
        var country = '';

        _.forEach(place.address_components, function (component) {
          if (component.types.indexOf('country') > -1) {
            country = component.short_name;
          }
        });

        var cityTypes = [];

        if (country == 'US') {
          cityTypes = ['administrative_area_level_5', // no U.S. equivalent, but just in case
          'administrative_area_level_4', // no U.S. equivalent, but just in case
          'administrative_area_level_3', // no U.S. equivalent, but just in case
          'sublocality_level_1', // relevant to cities like NYC, ex: Brooklyn, Staten Island
          'locality'];
        } else {
          cityTypes = ['administrative_area_level_5', // no U.S. equivalent
          'administrative_area_level_4', // no U.S. equivalent
          'administrative_area_level_3', // no U.S. equivalent
          'administrative_area_level_2', // In Brazil, for example, this is the city (county in US)
          'postal_town', // In the UK, this is the city. no U.S. equivalent
          'locality'];
        }

        // Attempt to squeeze Google's administrative levels into U.S. buckets.
        // Best option should be last.
        // See https://developers.google.com/maps/documentation/geocoding/intro#Types
        var types = {
          subpremise: ['subpremise'],
          number: ['street_number'],
          street: ['route'],
          city: cityTypes,
          state: ['administrative_area_level_1'],
          country: ['country'],
          postalCode: ['postal_code'],
          postalCodeSuffix: ['postal_code_suffix']
        };

        _.forEach(types, function (type, humanLabel) {
          _.forEach(type, function (typeLabel) {
            _.forEach(place.address_components, function (component) {
              if (component.types.indexOf(typeLabel) > -1) {
                if (humanLabel == 'country' || humanLabel == 'state') {
                  interpreted[humanLabel] = component.short_name;
                } else {
                  interpreted[humanLabel] = component.long_name;
                }
              }
            });
          });
        });

        interpreted.address = (interpreted.number + ' ' + interpreted.street).trim();
        interpreted.postalCode += interpreted.postalCodeSuffix ? '-' + interpreted.postalCodeSuffix : '';

        return interpreted;
      }
    }
  };
}
})();